export default [
  {
    key: 'no',
    label: 'field.shipmentNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'transferOrderNo',
    label: 'field.orderNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'transferShipmentDate',
    label: 'field.shipmentDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
];
