export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    stickyColumn: true,
    variant: 'light',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'no',
    label: 'field.shipmentNo',
    hideToggle: true,
  },
  {
    key: 'transferOrderNo',
    label: 'field.orderNo',
  },
  {
    key: 'transferFromName',
    label: 'field.transferFrom',
    subKey: 'transferFromCode',
    hideToggle: true,
  },
  {
    key: 'transferFromAddress',
    label: 'field.transferFromAddress',
  },
  {
    key: 'transferToName',
    label: 'field.transferTo',
    subKey: 'transferToCode',
    hideToggle: true,
  },
  {
    key: 'transferToAddress',
    label: 'field.transferToAddress',
  },
  {
    key: 'transferShipmentDate',
    label: 'field.shipmentDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
  },
];
